import { useForm } from "@mantine/form";
import {
  TextInput,
  NumberInput,
  Select,
  Button,
  Text,
  Divider,
  Space,
} from "@mantine/core";
import { useState } from "react";
import { ThankYouMessage } from "../../components/ThankYouMessage";
import {
  ClientEmailSearch,
  law_firm_of_user,
  searching_email,
} from "../ClientEmailSearch";

let submitting = false;

export const ForeclosureMD = (props: { dc?: boolean }) => {
  const is_dc_bond = props.dc ? props.dc : false;
  const [showingDoneMessage, setShowingDoneMessage] = useState(false);
  const form = useForm({
    initialValues: {
      substitute_trustees: "",
      county: is_dc_bond ? "District of Columbia" : "",
      file_number: "",
      debtor: "",
      creditor: "",
      case_number: "",
      liber: "",
      instrument_number: "",
      folio: "",
      date_of_mortgage: "",
      date_recorded: "",
      sender_street_address: "",
      sender_street_address2: "",
      sender_city: "",
      sender_zip: "",
      trustee_type: "Substitute Trustees",
      bond_amount: 25000,
      type_of_deed: "Deed of Trust",
      unusual: false,
      explorer_code: "",
      state: "",
      sender_email: "",
      sender_phone: "",
      rider_request: false,
      sale_date: "",
      time_created: Date.now(),
      bond_type: "foreclosure_md",
    },
    validate: {
      date_of_mortgage: (v: string) => {
        if (v === null || v.trim() === "") {
          return null;
        }
        const invalidCharsPattern = /[^0-9/]/;
        if (invalidCharsPattern.test(v)) {
          return "Invalid characters detected (only use / not - )";
        } else {
          return null;
        }
      },
    },
  });

  const handleSubmit = async () => {
    if (submitting || searching_email) return;
    submitting = true;
    try {
      const bond = form.values;
      console.log(bond)
      const chosenFirm = law_firm_of_user;
      console.log(law_firm_of_user)

      if (
        bond.trustee_type !== "Substitute Trustee" ||
        bond.bond_amount !== 25000 ||
        bond.type_of_deed !=="Deed of Trust"
      ) {
        bond.unusual = true;
      } else {
        bond.unusual = false;
      }
      // bond.substitute_trustees = chosenFirm.law_firm_name;
      const full_street_addy = chosenFirm.street_address.split(",");
      bond.sender_street_address = full_street_addy[0];
      bond.sender_street_address2 = full_street_addy[1]?.trim();
      bond.sender_city = chosenFirm.city;
      bond.state = chosenFirm.state;
      bond.sender_zip = chosenFirm.zip;
      bond.sender_email = chosenFirm.email;
      bond.sender_phone = chosenFirm.phone_number;
      if (bond.creditor.trim() === "") {
        bond.creditor = "";
      }

      bond.bond_type = is_dc_bond ? "foreclosure_dc" : "foreclosure_md";
      await fetch("https://api.insurancemastersinc.com/submission/submit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Ocd-Target": "SubmitBond",
        },
        body: JSON.stringify({
          ...bond,
          ...law_firm_of_user
        }),
      });
      setShowingDoneMessage(true);
      form.reset();
    } catch (err) {
      console.error(err);
    }
    submitting = false;
  };
  if (showingDoneMessage) {
    return <ThankYouMessage setShowingDoneMessage={setShowingDoneMessage} />;
  }

  return (
    // <Form
    //   onSubmit={() => {
    //     handleSubmit();
    //   }}
    //   form={form}
    // >

    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Text fz="30px">
        {is_dc_bond
          ? "DC Foreclosure Bond Request"
          : "Maryland Foreclosure Bond Request"}
      </Text>
      <Divider m="30px" />

      <Space h="50px" />
      <Select
        required
        label="County"
        searchable
        disabled={is_dc_bond}
        {...form.getInputProps("county")}
        placeholder="Select a county"
        data={is_dc_bond ? ["District of Columbia"] : marylandCounties}
      />
      <TextInput
        required
        label="Trustees"
        {...form.getInputProps("substitute_trustees")}
      />
      <TextInput label="Your File #" {...form.getInputProps("file_number")} />
      <TextInput required label="Debtor" {...form.getInputProps("debtor")} />
      <TextInput label="Creditor" {...form.getInputProps("creditor")} />
      <TextInput label="Case #" {...form.getInputProps("case_number")} />
      {is_dc_bond ? (
        <TextInput
          label="Instrument Number"
          {...form.getInputProps("instrument_number")}
        />
      ) : (
        <>
          <TextInput label="Folio" {...form.getInputProps("folio")} />
          <TextInput label="Liber" {...form.getInputProps("liber")} />
        </>
      )}
      {is_dc_bond && (
        <TextInput
          label="Date Recorded"
          placeholder="MM/DD/YYYY"
          {...form.getInputProps("date_recorded")}
        />
      )}
      <TextInput
        label="Date of Deed/Mortgage"
        placeholder="MM/DD/YYYY"
        {...form.getInputProps("date_of_mortgage")}
      />
      <TextInput
        label="Sale Date"
        placeholder="MM/DD/YYYY"
        {...form.getInputProps("sale_date")}
      />
      <Select
        required
        label="Trustee Type"
        data={trusteeTypes}
        {...form.getInputProps("trustee_type")}
        placeholder="Select type"
      />
      <NumberInput
        hideControls
        required
        label="Amount of Bond"
        {...form.getInputProps("bond_amount")}
      />
      <Select
        required
        label="Type of Deed"
        placeholder="Select type of deed"
        {...form.getInputProps("type_of_deed")}
        data={deedTypes}
      />
      <Divider />
      <ClientEmailSearch />

      <Button type="submit">Submit</Button>
      <Space h="200px" />
    </form>
    
  );
};

const marylandCounties = [
  "Allegany County",
  "Anne Arundel County",
  "Baltimore County",
  "Baltimore City",
  "Calvert County",
  "Caroline County",
  "Carroll County",
  "Cecil County",
  "Charles County",
  "Dorchester County",
  "Frederick County",
  "Garrett County",
  "Harford County",
  "Howard County",
  "Kent County",
  "Montgomery County",
  "Prince George's County",
  "Queen Anne's County",
  "St. Mary's County",
  "Somerset County",
  "Talbot County",
  "Washington County",
  "Wicomico County",
  "Worcester County",
];

const trusteeTypes = ["Substitute Trustees", "Trustees", "Assignee"];
const deedTypes = [
  "Deed of Trust",
  "Mortgage",
  "Decree of Sale",
  "Condominium Lien",
];
