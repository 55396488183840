export const searchClientsByEmail = async (email_address: string) => {
  const r = await fetch('https://api.insurancemastersinc.com/autofill/autofill', {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
      "X-OCD-TARGET": "SearchClientsByEmailAddress"
    }, body: JSON.stringify({ email_address })
  })
  const law_firms = JSON.parse(await r.text());
  return law_firms;
}

export const getQParam = (param: string) => {
  const url_params = new URLSearchParams(window.location.search);
  const value = url_params.get(param);
  if (value === null) {
    return undefined;
  } else { return value }
}
export const updateQParams = (param_values: {
  [parameter_name: string]: string | null
}) => {
  const current_url = new URL(window.location.href)
  const names = Object.keys(param_values);
  for (let i = 0; i < names.length; i++) {
    const name = names[i]
    const value = param_values[name];
    if (value === null) {
      current_url.searchParams.delete(name);
      continue;
    }
    current_url.searchParams.set(name, value)
  }
  window.history.replaceState(null, "", "?" + current_url.searchParams.toString())
}
