import { Stack, Card, Space, Divider, Button, Text } from "@mantine/core";
import { IconCheck } from "@tabler/icons-react";

export interface ThankYouMessageProps {
  setShowingDoneMessage: (state: boolean) => void;
}

export const ThankYouMessage = (props: ThankYouMessageProps) => {
  return (
    <Stack justify="center" align="center" h="100%">
      <Card style={{ alignItems: "center" }}>
        <Space h="40px" />
        <Card
          style={{
            borderRadius: "100%",
            width: "100px",
            height: "100px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <IconCheck color="limegreen" size={100} />
        </Card>
        <Space h="40px" />
        <Text>Thank you for submitting your bond request</Text>
        <Text fw={600}>Expect an email response shortly</Text>
        <Divider />
        <Space h="50px" />
        <Button onClick={() => props.setShowingDoneMessage(false)}>
          Submit another
        </Button>
      </Card>
    </Stack>
  );
};
