import {
  Button,
  Divider,
  NumberInput,
  Select,
  Space,
  Stack,
  Text,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useState } from "react";
import {
  ClientEmailSearch,
  law_firm_of_user,
  searching_email,
} from "../ClientEmailSearch";
import { ThankYouMessage } from "../../components/ThankYouMessage";
let submitting = false;

export const NominalAndPersonalBondUnder25 = (props: {
  type: "NOMINAL" | "PERSONAL";
}) => {
  const [showingDoneMessage, setShowingDoneMessage] = useState(false);
  // const [emailError, setEmailError] = useState(false);
  const [maxBondAmountError, setMaxBondAmountError] = useState(false);
  const form = useForm({
    initialValues: {
      county: "",
      name_of_deceased: "",
      bond_amount: 25000,
      personal_rep: "",
      personal_rep_street_address: "",
      personal_rep_city: "",
      personal_rep_state: "",
      personal_rep_zip: "",
      explorer_code: "",
      sender_email: "",
      sender_phone: "",
      time_created: Date.now(),
    },
  });
  if (showingDoneMessage) {
    return <ThankYouMessage setShowingDoneMessage={setShowingDoneMessage} />;
  }
  return (
    <Stack>
      <Text fz="30px">
        {props.type === "NOMINAL"
          ? "Nominal Bond of Personal Rep less than $25,000 MD"
          : "Bond of Personal Rep less than $25,000 MD"}
      </Text>
      <Divider m="30px" />
      <Space h="50px" />
      <Select
        required
        label="County"
        searchable
        {...form.getInputProps("county")}
        placeholder="Select a county"
        data={marylandCounties}
      />
      <TextInput
        required
        label="Name of the Deceased"
        {...form.getInputProps("name_of_deceased")}
      />
      <NumberInput
        required
        label="Amount of the bond"
        min={1000}
        hideControls
        allowDecimal={false}
        {...form.getInputProps("bond_amount")}
        onChange={(v) => {
          setMaxBondAmountError(Number(v) > 25000);
          form.getInputProps("bond_amount").onChange(v);
        }}
      />
      {maxBondAmountError && (
        <Text m={0} p={0} ta="left" fz="xs" c="red">
          Wrong bond form, this form is only for bonds under $25k
        </Text>
      )}
      <TextInput
        required
        label="Personal Representative as you want it to appear on the bond"
        {...form.getInputProps("personal_rep")}
      />
      <TextInput
        required
        label="Personal Representative Street Address if multiple PR's only enter one address."
        {...form.getInputProps("personal_rep_street_address")}
      />
      <TextInput
        required
        label="Personal Representative City"
        {...form.getInputProps("personal_rep_city")}
      />
      <TextInput
        required
        label="Personal Representative State"
        {...form.getInputProps("personal_rep_state")}
      />
      <TextInput
        required
        maxLength={9}
        label="Personal Representative Zip"
        {...form.getInputProps("personal_rep_zip")}
      />
      <ClientEmailSearch />
      {maxBondAmountError && (
        <Text c="crimson">
          This is the wrong form, this form is for bonds UNDER $25,000
        </Text>
      )}
      <Button
        onClick={() => {
          if (maxBondAmountError || submitting || searching_email) {
            return;
          }
          submitting = true;

          fetch("https://api.insurancemastersinc.com/submission/submit", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "X-Ocd-Target": "SubmitBond",
            },
            body: JSON.stringify({
              ...form.getValues(),
              ...law_firm_of_user,
              bond_type:
                props.type === "NOMINAL"
                  ? "nominal_under_25_md"
                  : "personal_under_25_md",
            }),
          }).then(() => {
            setShowingDoneMessage(true);
            form.reset();

            submitting = false;
          });
        }}
      >
        Submit
      </Button>
      <Space h="200px" />
    </Stack>
  );
};
const marylandCounties = [
  "Allegany County",
  "Anne Arundel County",
  "Baltimore County",
  "Baltimore City",
  "Calvert County",
  "Caroline County",
  "Carroll County",
  "Cecil County",
  "Charles County",
  "Dorchester County",
  "Frederick County",
  "Garrett County",
  "Harford County",
  "Howard County",
  "Kent County",
  "Montgomery County",
  "Prince George's County",
  "Queen Anne's County",
  "St. Mary's County",
  "Somerset County",
  "Talbot County",
  "Washington County",
  "Wicomico County",
  "Worcester County",
];
