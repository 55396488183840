import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {Button, Card, Checkbox, createTheme, Divider, MantineProvider, NumberInput, Select, TextInput} from "@mantine/core";
import { IconCalendar } from '@tabler/icons-react';
import './global.css'
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import { DatePickerInput } from '@mantine/dates';


export const theme = createTheme({
  fontFamily: 'Poppins',
  fontSizes: {
    "xs":"14px",
    "sm":"18px",
    "md":"22px",
    "lg":"30px",
    "xl":"50px"
  },
  components: {
    Checkbox: Checkbox.extend({
      defaultProps: {
        size: "md", mt:"15px", mb:"15px"
      }
    }),
    DatePickerInput: DatePickerInput.extend({
      defaultProps: {
        size: "md",
        leftSection: <IconCalendar color="black" size={30}/>,
        defaultLevel:"decade", 
        dropdownType: "modal", 
          modalProps: {
            centered: true,
            title:"Use arrows to see more years"
          },
        mb: "25px"
      }
    }),
    Divider: Divider.extend({
      defaultProps: {
        mb:'20px', mt:'10px', color:'gray'
      }
    }),
    TextInput: TextInput.extend({
      defaultProps: {
        variant: "filled",
        radius: "xs",
        size: "md",
        fw:500,
        pb:"10px", ta:'left'
      }
    }),
    NumberInput: NumberInput.extend({
      defaultProps: {
        variant: "filled",
        radius: "xs",
        size: "md",
        fw:500,
        pb:"10px", ta:'left'
      }
    }),
    Select: Select.extend({
      defaultProps: {
        variant: "filled",
        radius: "xs",
        size: "md",
        fw:500,
        pb:"10px", ta:'left'
      }
    }),
    Button: Button.extend({
      defaultProps: {
        bg: "#064b69",
        fz:"md", size:"lg",
        // variant: "outline"
      }
    }),
    Card: Card.extend({
      defaultProps: {
        shadow: "xs", withBorder: true
      }
    })

  },
  shadows: {
    xl: '0px 0px 15px 10px rgba(0, 0, 0, .15)',
  },
})


ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <MantineProvider theme={theme} forceColorScheme='light'>
      <App />
    </MantineProvider>
  </React.StrictMode>
);