import { Card, MantineProvider } from '@mantine/core';
import { Text } from '@mantine/core';
import './App.css';
import { getQParam } from './Protocol';
import { ForeclosureMD } from './forms/foreclosure-md/foreclosure-md';
import { NominalAndPersonalBondUnder25 } from './forms/nominal_under25md/NominalAndPersonalBondUnder25';

function App() {

  const selected_form = getQParam("form");
  

  switch (selected_form) {
    case "foreclosure-md":
      return <ForeclosureMD />;
      break;
    case "foreclosure-dc":
      return <ForeclosureMD dc={true} />;
    case "nominal-under25-md":
      return <NominalAndPersonalBondUnder25 type="NOMINAL" />;
    case "personal-under25-md":
      return <NominalAndPersonalBondUnder25 type="PERSONAL" />;
    default:
      return (
        <Card w="100%" h="100%">
          <Text>Form not found</Text>
        </Card>
      );
  }

}

export default App;

